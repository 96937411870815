import { DestroyRef, Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { API_ENDPOINTS } from '@app/shared/config/endpoints';
import { IActivitySession, IActivitySessionResume, IAudioSession, IPuzzle, IRecording } from '../types/activity-session-interface';
import { AppStateService } from './app-state.service';
import { APP_EVENT_AREAS } from '../constants/app-event-areas';
import { PUZZLE_STATUS } from '../constants/session-enums';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AppHelpers } from '../helpers/app-helpers';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ActivitySessionService {
  private _http = inject(HttpClient);
  private _destroyRef = inject(DestroyRef);
  private _appStateService = inject(AppStateService);
  private _trackingService = inject(TrackingService);
  private _sessionStartTime: number;
  private _session: IActivitySession;
  private _showAudioRepeatButton = new BehaviorSubject<boolean>(true);
  private _showExpressViewer = new BehaviorSubject<boolean>(false);
  _showAudioRepeatButton$ = this._showAudioRepeatButton.asObservable();
  _showExpressViewer$ = this._showExpressViewer.asObservable();

  constructor() { }

  initSession(activitySessionId: string, resumeSession: IActivitySessionResume | null) {
    const prevDuration = resumeSession ? resumeSession.duration * 1000 * 60 : 0; // in milliseconds
    this._sessionStartTime = new Date().getTime() - prevDuration;

    this._session = {
      activitySessionId: activitySessionId,
      assignmentId: resumeSession ? resumeSession.activitySessionId : 'mockAssignmentId',
      score: resumeSession ? resumeSession.score : 0,
      duration: resumeSession ? resumeSession.duration : 0,
      completed: resumeSession ? resumeSession.completed : false,
      puzzlesJSON: resumeSession ? resumeSession.puzzlesJSON : '',
      recordingsJSON: '',
      puzzles: resumeSession ? JSON.parse(resumeSession.puzzlesJSON) : [],
      recordings: [],
    };

    this.updateSession(this._session.score);
  }

  updateSession(score: number) {
    this._session.duration = (new Date().getTime() - this._sessionStartTime) / 1000 / 60; // milliseconds to seconds
    this._session.score = score;
    this.update(this._session);
    this._appStateService.db('Update Session', this._session);

    if (this.getPuzzleCount() > 0) {
      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.SHOW_SCORE,
      });
    }
  }

  addPuzzle(puzzle: IPuzzle) {
    this._session.puzzles.push(puzzle);
  }

  getPuzzleCount() {
    return this._session.puzzles.length;
  }

  getPassedPuzzleCount() {
    return this._session.puzzles.filter((p) => p.result === PUZZLE_STATUS.PASS).length;
  }

  setSessionCompleted() {
    this._session.completed = true;
    this._trackingService.track({ name: 'FHPCompletedActivity', props: null });
  }

  isSessionCompleted() {
    return this._session.completed;
  }

  async processAudio(audio: Blob, expression: string): Promise<void> {
    //call the API to post the audio
    const url = API_ENDPOINTS.audioSession;
    const payload = this.generateAudioSessionPayload(audio);

    this._http
      .post(url, payload, { responseType: 'text' }) 
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (result) => {
          if (result) {
            const recording: IRecording = {
              expression: expression,  
              file: result,            
            };

            this._session.recordings = [...this._session.recordings, recording];
          }
        }
      });
  }

  public update(session: IActivitySession) {

    if (session.activitySessionId == 'null') return;

    const url = API_ENDPOINTS.submitActivitySession;

    session.puzzlesJSON = JSON.stringify(session.puzzles);
    session.recordingsJSON = JSON.stringify(session.recordings);

    this._http
      .post<any>(url, session)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: (result) => {
          // true,  false return , if an error happens our error handler will catch it. should we do anything to user UI if false?
        },
      });
  }

  public getSessionMetrics(): Observable<IActivitySessionResume | undefined> {

    const token = this._appStateService.persistedUrlParams['token'];

    if (AppHelpers.isActivitiesBypass() || token) {
      const session: IActivitySessionResume = {
        activitySessionId: '123',
        score: 0,
        duration: 0,
        completed: false,
        puzzlesJSON: '[]',
        recordingsJSON: '[]',
      };
      return of(session);
    }

    const asi = this._appStateService.persistedUrlParams['asi'];
    const url = `${API_ENDPOINTS.resumeActivitySession}/${asi}`;
    return this._http.get<IActivitySessionResume>(url);
  }

  private generateAudioSessionPayload(recording: Blob): FormData {
    const form = new FormData();

    const dataObj: IAudioSession = {
      activitySessionId: this._session.activitySessionId,
      assignmentId: this._session.assignmentId,
      audio: recording,
    };

    for (const key in dataObj) {
      form.append(key, dataObj[key]);
    }
    return form;
  }

  setShowAudioRepeatButton(show: boolean) {
    this._showAudioRepeatButton.next(show);
  }

  setExpressViewer(show:boolean){
    this._showExpressViewer.next(show);
  }
}
