import { Component, DestroyRef, HostListener, inject, OnDestroy } from '@angular/core';
import { filter, forkJoin, map, Observable, Subject, Subscription } from 'rxjs';
import { COLON_SEPARATOR, SYLLABLE_SEPARATOR } from '@app/shared/constants/activity-constants';
import { SoundService } from '@app/shared/services/sound.service';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SCORE_RANGE, ScoringService } from '@app/shared/services/scoring.service';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';
import { IActivityRegistry } from '@app/shared/types/activity-registry.interface';
import { IActivitySessionResume, IMistake, IPuzzle } from '@app/shared/types/activity-session-interface';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';
import { AppHelpers } from '@app/shared/helpers/app-helpers';


@Component({
  selector: 'app-activity-base',
  template: ``,
  styles: [],
  standalone: true,
  providers: [AppStateService],
})
export abstract class ActivityBaseComponent implements OnDestroy {
  public keyEvent: Subject<KeyboardEvent> | undefined;
  public keySub: Subscription | undefined;

  protected activitySolved = false;

  protected currentActivity: IActivityRegistry;
  protected activityReady = false;

  protected markerCount = 0;
  protected markerImage = '';
  protected placeHolderMarkerImage = '';

  protected howToVideoMode = false; // set to true when showing the how to video for the activity

  protected soundService = inject(SoundService);


  protected assetsPath = '/assets/';
  protected appStateService = inject(AppStateService);
  protected destroyRef = inject(DestroyRef);


  // scoring
  private _scoringService = inject(ScoringService);

  private _expressions: Map<number, string> = new Map();

  private _sessionService = inject(ActivitySessionService);

  // Derived classes must implement these
  abstract showActivitySolved(): void;
  abstract startActivity(): void;

  // use keydown event here because keyup is too fast to prevent default browser behavior in the cases that we need it.
  @HostListener('document:keydown', ['$event']) handleKeyEvent(event: KeyboardEvent): void {
    this.keyEvent?.next(event);
  }

  protected constructor() {
    this.keyEvent = new Subject();


    // track current activity
    this.appStateService.currentActivity$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((activity) => {
      if (activity) {
        this.currentActivity = activity;
      }
    });

    // start activity once we get the signal from the app state service
    this.appStateService.appEvent$.pipe(
      filter((event) => event.area === APP_EVENT_AREAS.START_GAME),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.startActivity());

    // Listen for the COMPLETE_ALL_PUZZLES event
    this.appStateService.appEvent$
      .pipe(
        filter((event) => event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES),
        takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.completeAllPuzzles()
      );
  }

  ngOnDestroy(): void {
    if (this.keySub) {
      this.keySub.unsubscribe();
    }
  }

  loadDataset(): Observable<any> {
    return forkJoin([this.appStateService.activityDataset$, this._sessionService.getSessionMetrics()]).pipe(
      map((result) => {
        const dataset: any = result[0];
        const sessionResume = result[1] as IActivitySessionResume;
        let targetPuzzle = AppHelpers.autoCompletePuzzles();
        if (sessionResume && targetPuzzle === -1) {
          targetPuzzle = JSON.parse(sessionResume.puzzlesJSON).length; // next puzzle will be the 1 after the last one that was recorded
        }

        // mark puzzles completed as needed
        dataset.forEach((d, idx) => {
          d.completed = idx < targetPuzzle;
        });

        this.initProgressBar();
        this.initSession(sessionResume);
        this.activityReady = true;
        return dataset;
      })
    );
  }

  // TODO Remove, now in Puzzle Type base
  onArrowKey(event: KeyboardEvent) {
    const el = event.target as HTMLElement;
    if (el) {
      switch (event.key) {
        case KEY_EVENTS.ArrowDown:
        case KEY_EVENTS.ArrowRight:
          (el.nextSibling as HTMLElement)?.focus();
          break;
        case KEY_EVENTS.ArrowUp:
        case KEY_EVENTS.ArrowLeft:
          (el.previousSibling as HTMLElement)?.focus();
          break;
      }
    }
  }

  initProgressBar() {
    this.markerImage = `${this.assetsPath}${this.currentActivity?.theme?.progressBar.marker}`;
    this.placeHolderMarkerImage = `${this.assetsPath}${this.currentActivity?.theme?.progressBar.markerPlaceholder}`;
  }

  buildExpressions(dataset: any){
    this._expressions.clear();

    const expressionsString = dataset?.expressions;

    if (expressionsString) {
      const expressionsLocal = expressionsString.split(SYLLABLE_SEPARATOR);
      expressionsLocal.forEach((e, idx) => {
        const exp = e.split(COLON_SEPARATOR);
        const key = idx + 1;
        let value: string;
        if (exp.length === 1) {
          value = exp[0].trim();
        } else {
          value = exp.length > 1 ? exp[1].trim() : '';
        }
        this._expressions.set(key, value.trim());
      });
    } else {
      this._expressions.clear();
    }

  }

  playEndExpression(callback?: () => void | undefined) {
    let exp = '';
    const range: SCORE_RANGE = this._scoringService.getScoreRange();
    if (range === SCORE_RANGE.LOW) {
      exp = this.currentActivity.endExpressionLow;
    } else if (range === SCORE_RANGE.MEDIUM) {
      exp = this.currentActivity.endExpressionMedium;
    } else {
      exp = this.currentActivity.endExpressionHigh;
    }

    this.soundService.playExpression(exp, () => {
      callback ? callback() : undefined;
    });
  }

  get expressions() {
    return this._expressions;
  }

  getExpression(key: number) {
    return this._expressions.get(key);
  }

  // Scoring proxy methods
  setPerfectScore(score: number) {
    this._scoringService.setPerfectScore(score);
  }


  //---Session methods --------------
  initSession(session: IActivitySessionResume | null) {
    this._sessionService.initSession(this.appStateService.persistedUrlParams['asi'], session);
    if (session) {
      this._scoringService.resumeScore(session);
    }
    this.updateSession();
  }

  updateSession() {
    const score = this._scoringService.calculateScore();
    this._sessionService.updateSession(score);
  }

  toggleActivityCompletePage(show: boolean) {
    if (show) {
      if (!this._sessionService.isSessionCompleted()) {
        this._sessionService.setSessionCompleted();
        this._scoringService.calculateScore();
        this.updateSession();
      }
    }

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.ACTIVITY_COMPLETE,
      showActivityComplete: show,
      score: this._scoringService.calculateScore(),
    });

  }

  protected completeAllPuzzles(): void {
    this.appStateService.activityDataset$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(dataset => {
        if (dataset) {
          dataset.forEach((puzzle: any) => puzzle.completed = true);
          this.initProgressBar(); // Assuming this updates the UI
          this.appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SET_ACTIVITY_DATASET,
            dataset: dataset
          });
        }
      });
  }
}
