export enum APP_EVENT_AREAS {
  Sound = 'sound',
  UI = 'UI',
  ACTIVITY_COMPLETE = 'Activity Complete',
  WELCOME = 'Welcome',
  PLAY_AGAIN = 'Play Again',
  PUZZLE_CLUE = 'Puzzle Clue',
  START_GAME = 'Start Game',
  SET_ACTIVITY_DATASET = 'Set Activity Dataset',
  SET_ACTIVITY_REGISTRY = 'Set Activity Registry',
  SHRINK_NARRATOR = 'Shrink Narrator',
  CENTER_NARRATOR = 'Center Narrator',
  PROFILE_NARRATOR = 'Profile Narrator',
  BOUNCE_NARRATOR = 'Bounce the object in Narrator',
  SHOW_INFO = 'Show Information',
  HOW_TO_PLAY = 'How to Play',
  SHOW_SCORE = 'Show Score',
  AUDIO_ERROR = 'Audio Error',
  JUMP_TO_PUZZLE = 'Jump to Puzzle',
  PUZZLE_COMPLETION_UPDATE = 'Puzzle Completion Update', 
  COMPLETE_ALL_PUZZLES = 'COMPLETE_ALL_PUZZLES',
  SKIP_INTRODUCTION = 'Skip Introduction',
  RECORDING_STATE_UPDATE = 'Recording State Update'
}
