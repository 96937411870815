import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output, DestroyRef } from '@angular/core';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { IWorldOfWordsDataset } from '../../types/world-of-words-dataset-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AudioRecordingService } from '@app/shared/services/audio-recording.service';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AudioVisualizerComponent } from '@app/shared/components/audio-visualizer/audio-visualizer.component';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-wow-puzzle-type-2',
  templateUrl: './puzzle-type-2.component.html',
  styleUrls: ['./puzzle-type-2.component.scss'],
  imports: [CommonModule, NgOptimizedImage, AudioVisualizerComponent, TooltipModule],
  standalone: true,
  providers: [],
})
export class WOWPuzzleType2Component extends PuzzleTypeBaseComponent {
  @Input() currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();

  protected isPuzzleSolved = false;

  private _appState = inject(AppStateService);
  private _audioRecordingService = inject(AudioRecordingService);
  private _activitySessionService = inject(ActivitySessionService);
  private stopTimeout: any = null;
  private totalRecordingTime: number = 0;
  private maxRecordingTime: number = DELAY.S90;
  private recordingInterval: any = null;
  private _destroyRef = inject(DestroyRef);



  // Puzzle Type 2
  protected finalParagraphReview: string;
  protected rightButtonImage = '';
  protected isRecording: boolean = false;
  protected isRecordingEnabled: boolean = false;
  protected isRecordingCompleted: boolean = false;
  protected currentLevel: number | undefined = this._appState.level;

  protected recorderInstance: any = null;
  protected isRecordingActive: boolean = false;
  protected micButtonImage = '/assets/blue-mic-button.svg';
  protected pauseButtonImage = '/assets/pause-recording-button.svg';
  protected soundBarImage = '/assets/sound-bar.svg';
  constructor() {
    super();
    this.emitCall = this.puzzleComplete;

    // Subscribe to the recording state from AppStateService
    this._appState.isRecordingEnabled$
      .pipe(takeUntilDestroyed(this._destroyRef)) 
      .subscribe((isRecordingEnabled: boolean) => {
        this.isRecordingEnabled = isRecordingEnabled;
      });

    this.isRecording = this.isRecordingEnabled;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWorldOfWordsDataset?.maxTries)
    this.startPuzzleMetrics();
    // show paragraph, then wait for 1 minute to show continue button
    this.finalParagraphReview = this.currentWorldOfWordsDataset?.sentence ? this.currentWorldOfWordsDataset?.sentence : '';
    setTimeout(() => {
      this.rightButtonImage = `/assets/arrow-right-dark-${this.currentLevel}.svg`;
      setTimeout(() => {
        AnimationHelpers.animate('continue-button', ANIMATIONS.Pulse);
      }, DELAY.S1);
    }, DELAY.S5);
  }

  async doRecord() {
    await this.recordAudio("Recording session started");
  }

  private async recordAudio(expression: string): Promise<void> {
    if (!this.isRecordingEnabled) return;

    this.recorderInstance = null;
    const recorder = await this._audioRecordingService.recordAudio();
    this.recorderInstance = recorder;
    recorder.start();

    this.isRecordingActive = true;
    this.isRecording = true;

    this.monitorRecording();
  }

  private monitorRecording() {
    // Start or continue monitoring the recording time
    if (this.recordingInterval) clearInterval(this.recordingInterval); 

    this.recordingInterval = setInterval(() => {
      // Increment the total recording time by 1 second
      this.totalRecordingTime += DELAY.S1;

      // Check if the total recording time has exceeded the max recording time
      if (this.totalRecordingTime >= this.maxRecordingTime) {
        clearInterval(this.recordingInterval); 
        this.stopRecording().then(() => {
          setTimeout(() => {
            this.onNextPuzzle(); 
          }, DELAY.S1);
        });
      }
    }, DELAY.S1);
  }

  async pauseRecording() {
    if (this.recorderInstance && this.isRecordingActive) {
      this.recorderInstance.pause(); 
      
      this.isRecordingActive = false; 
      
      setTimeout(() => {
        AnimationHelpers.animate(`continue-button`, ANIMATIONS.Pulse, DELAY.M30);
      }, DELAY.S1);
      
      if (this.recordingInterval) clearInterval(this.recordingInterval);
      
    }
  }

  async resumeRecording() {
    if (this.recorderInstance && !this.isRecordingActive) {
      this.recorderInstance.resume();
      this.isRecordingActive = true; 

      // Continue monitoring the recording to ensure the total time does not exceed 90 seconds
      this.monitorRecording();
    }
  }

  async stopRecording() {
    if (this.stopTimeout) {
      clearTimeout(this.stopTimeout);
      this.stopTimeout = null;
    }

    if (this.recorderInstance) {
      const recording = await this.recorderInstance.stop();
      await this._activitySessionService.processAudio(recording.audioBlob, "User stopped recording");

      // Reset the recording state
      this.isRecordingActive = false; 
      this.isRecordingCompleted = true;
    }
  }

  async stopRecordingAndContinue() {
    clearInterval(this.recordingInterval); 

    this.isRecordingCompleted = true;

    await this.stopRecording();

    setTimeout(() => {
      this.onNextPuzzle(); 
    }, DELAY.S1);

  }  

  onNextPuzzle() {
    this.completePuzzle(PUZZLE_STATUS.PASS, this.currentWorldOfWordsDataset?.sentence ?? ''); // pass paragraph to parent as its needed in pt 3);
  }
}
