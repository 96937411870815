<div class="mic-popup">
    <div class="popup-content">
      <img src="{{ exitButtonImage }}" class="exit-button" (click)="onDoNotAllow()" alt="Exit">
      <h3>Select "Allow" to let FUN HUB Practice use your microphone for recording words and phrases.</h3>
      <p class="instrunctions-2">When you do, this will allow you to interact with FUN HUB Practice Activities.</p>
      <div class="popup-actions">
        <button (click)="onDoNotAllow()">Do Not Allow</button>
        <button (click)="onAllow()">Allow</button>
      </div>
      <img src="{{ textToSpeechButton }}" class="text-to-speech-button" (click)="playMicAccessPopupAudio()" alt="text to speech icon" aria-hidden="true">
    </div>
  </div>
