import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, ViewChild } from '@angular/core';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IWordFindFunDataset } from './types/word-find-fun-dataset-interface';
import { WFFPuzzleType0Component } from './puzzles/0/puzzle-type-0.component';
import { WFFPuzzleType1Component } from './puzzles/1/puzzle-type-1.component';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';

enum PUZZLE_TYPE {
  Marking = 0,
  Word,
  Unknown,
}

@Component({
  selector: 'app-word-find-fun',
  templateUrl: './word-find-fun-activity.component.html',
  styleUrls: ['./word-find-fun-activity.component.scss'],
  imports: [CommonModule, WFFPuzzleType0Component, WFFPuzzleType1Component, ProgressBarComponent],
  standalone: true,
  providers: [],
})
export class WordFindFunActivityComponent extends ActivityBaseComponent {
  protected wordFindFunDatasets: IWordFindFunDataset[];
  protected currentWordFindFunDataset: IWordFindFunDataset | undefined;

  protected puzzleTypeEnum = PUZZLE_TYPE;
  protected puzzleType: PUZZLE_TYPE;

  protected carouselProceed = false;
  protected totalPuzzles: number = 0;
  protected answeredPuzzles: number = 0;


  @ViewChild(WFFPuzzleType0Component)
  private _puzzleType0: WFFPuzzleType0Component;
  @ViewChild(WFFPuzzleType1Component)
  private _puzzleType1: WFFPuzzleType1Component;

  private _destroyRef = inject(DestroyRef);


  @ViewChild(ProgressBarComponent)
  private _progressBarComp!: ProgressBarComponent;

  constructor() {
    super();
  }

  processKeyEvent(event: KeyboardEvent) { }

  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((datasetSrc) => {
        this.wordFindFunDatasets = (datasetSrc as IWordFindFunDataset[]).filter((d) => d.puzzleType === PUZZLE_TYPE.Word);
        this.totalPuzzles = this.wordFindFunDatasets.length;
        this.currentWordFindFunDataset = this.wordFindFunDatasets?.find((d) => !d.completed);
        this.markerCount = this.wordFindFunDatasets?.length;
        this.answeredPuzzles = this.wordFindFunDatasets.filter((d) => d.completed)?.length;
        callback ? callback() : undefined;

        this.listenForJumpToPuzzle();

      });

    this.listenForCompleteAllPuzzles();
  }

  initPuzzle() {
    if (!this.currentWordFindFunDataset) {
      this.showActivitySolved();
      return;
    }

    this.puzzleType = this.currentWordFindFunDataset?.puzzleType || 0;
    this.buildExpressions(this.currentWordFindFunDataset);

    setTimeout(() => {
      switch (this.puzzleType) {
        case PUZZLE_TYPE.Marking:
          this._puzzleType0.initPuzzle();
          break;
        case PUZZLE_TYPE.Word:
          this._puzzleType1.initPuzzle();
          break;
        default:
          break;
      }
    }, DELAY.ZERO);
  }

  getNextDataset(status) {
    this.updateSession();

    if (this.currentWordFindFunDataset) {
      this.currentWordFindFunDataset.completed = true;
      this._progressBarComp.proceed(() => {
        this.currentWordFindFunDataset = this.wordFindFunDatasets?.find((tw) => !tw.completed);
        this.initPuzzle();
      }, status);
    }
  }

  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.puzzleType = PUZZLE_TYPE.Unknown;
      this._progressBarComp.reset();

      this.playEndExpression(() => { });
      this.toggleActivityCompletePage(true);
    });
  }

  startActivity() {
    if (this.activitySolved) {
      this.activitySolved = false;
      this.toggleActivityCompletePage(false);
    }

    this.initActivity(() => {
      this.setPerfectScore(this.wordFindFunDatasets?.length || 0);

      this.soundService.playExpression(this.currentActivity?.startExpression, () => {
        this._progressBarComp.markedComplete(this.wordFindFunDatasets.filter((d) => d.completed)?.length);
        this.initPuzzle();
      });
    });
  }

  onPuzzleComplete(puzzleComplete: IPuzzleCompleteEvent) {
    this.getNextDataset(puzzleComplete.status);
  }

  onCarouselCompleted() {
    this.carouselProceed = true;
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.wordFindFunDatasets.length) {
          // Set the correct dataset
          this.currentWordFindFunDataset = this.wordFindFunDatasets[event.payload];

          // Ensure the puzzle components reset their states
          this._puzzleType1.carouselCompleted = false;
          this._puzzleType1.carouselProceed = false;

          // Set flags to skip carousel and proceed directly to the puzzle
          this._puzzleType1.carouselCompleted = true;
          this._puzzleType1.carouselProceed = true;
          this.carouselProceed = true;

          // Update the puzzle type and reinitialize the puzzle UI
          this.puzzleType = this.currentWordFindFunDataset?.puzzleType || PUZZLE_TYPE.Unknown;
          this.buildExpressions(this.currentWordFindFunDataset);

          setTimeout(() => {
            switch (this.puzzleType) {
              case PUZZLE_TYPE.Marking:
                this._puzzleType0.initPuzzle();  // Initialize the new puzzle
                break;
              case PUZZLE_TYPE.Word:
                this._puzzleType1.initPuzzle();  // Initialize the new puzzle
                break;
              default:
                break;
            }
          }, DELAY.ZERO);
        }
      }
    });
  }

  displayPB() {
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
  }

  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();
        this.updateActivityAfterCompletion();
      }
    });
  }

  private updateActivityAfterCompletion(): void {
    // Check if all puzzles are completed and update the UI accordingly
    this.currentWordFindFunDataset = this.wordFindFunDatasets?.find((d) => !d.completed);
    if (!this.currentWordFindFunDataset) {
      this.showActivitySolved(); // Show activity completed if all puzzles are done
    } else {
      this.initPuzzle(); // Reinitialize the current puzzle if there are any left
    }
  }

}
