import { Component, EventEmitter, Output} from '@angular/core';
import { CommonModule} from '@angular/common';
import { environment } from '@env/environment';
import { SoundService } from '@app/shared/services/sound.service';


@Component({
  selector: 'app-mic-access-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './mic-access-popup.component.html',
  styleUrls: ['./mic-access-popup.component.scss'],
})
export class MicAccessPopupComponent{
    @Output() allowAccess = new EventEmitter<boolean>(); // Emits whether the user allowed access or not
    protected exitButtonImage = `/assets/exit-button-black.svg`;
    protected textToSpeechButton = '/assets/text-to-speech-active.svg'

    private _soundService: SoundService

    constructor() {}


    onAllow() {
      this.allowAccess.emit(true);  // Emit true when the "Allow" button is clicked
    }
  
    onDoNotAllow() {
      this.allowAccess.emit(false);  // Emit false when the "Do Not Allow" button is clicked
    }

    playMicAccessPopupAudio(){
   
      this._soundService.playFile(
        `${environment.CDN_URL}/student/audio/BB2539B433A7B299C21840E7E4485195E40E9FCB80F9CBADD334F691997D03A3.mp3`,
        1,
        false
      );
    }
}
