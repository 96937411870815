<ng-container>
  <p class="read-paragraph">
    {{ this.currentWorldOfWordsDataset?.sentence }}
  </p>
  <div class="mic-sound-container" *ngIf="isRecording && !isRecordingCompleted && this.rightButtonImage.length > 0">
    <!-- Record/Resume Button -->
    <button
        *ngIf="!isRecordingActive"
        class="mic image-button"
        (click)="!recorderInstance ? doRecord() : resumeRecording()"
        aria-label="Record"
        pTooltip="{{ !recorderInstance ? 'Record Button' : 'Resume Recording Button' }}"
        tooltipPosition="left"
    >
      <img [src]="micButtonImage" height="71" width="106" alt="microphone icon" aria-hidden="true" />
    </button>

    <!-- Pause Button When Recording is Active -->
    <button
      *ngIf="isRecordingActive"
      class="pause image-button"
      (click)="pauseRecording()"
      [disabled]="!recorderInstance"
      aria-label="Pause"
      pTooltip="Pause Recording Button"
      tooltipPosition="left"
    >
      <img [src]="pauseButtonImage" height="71" width="106" alt="pause icon" aria-hidden="true" />
    </button>
    
    <!-- Sound bar and Continue Button -->
    <div class="sound-bar-arrow-container">
      <app-audio-visualizer></app-audio-visualizer>
      <button
        *ngIf="recorderInstance && !isRecordingActive && isRecording && !isRecordingCompleted"
        id="continue-button"
        (click)="stopRecordingAndContinue()"
        (keyup.enter)="stopRecordingAndContinue()"
        (keyup.space)="stopRecordingAndContinue()"
        aria-label="Done / Next Activity"
        pTooltip="Done / Next Activity"
        tooltipPosition="left"
        class="continue-button continue-recording"
      >
        <img [ngSrc]="rightButtonImage" height="71" width="106" alt="continue button" />
      </button>
    </div>
  </div>

  <!-- Continue Button Outside Recording -->
  <button
    *ngIf="!isRecording && this.rightButtonImage.length > 0"
    id="continue-button"
    (click)="onNextPuzzle()"
    (keyup.enter)="onNextPuzzle()"
    (keyup.space)="onNextPuzzle()"
    aria-label="Continue"
    pTooltip="Continue"
    tooltipPosition="left"
    class="continue-button"
  >
    <img [ngSrc]="rightButtonImage" height="71" width="106" alt="continue button" />
  </button>
</ng-container>